<template>
  <div id="app">
    <Menu></Menu>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from "./components/menu.vue";
import Footer from "./components/footer.vue";

export default {
  components: { Menu, Footer },
};
</script>

<style>
@import url('./assets/css/global.less');
body,
html {
  min-width: 320px;
  margin: 0;
  padding: 0;
}
#app {
  font: 14px/1.7 Titillium, "微软雅黑", Arial, "黑体", "宋体", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
</style>
