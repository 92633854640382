<template>
  <el-row type="flex">
    <el-row align="middle" type="flex" class="row-bg" justify="center">
      <el-col :span="8">
        <a href="/"><img class="logo" src="../assets/logo.svg" /></a>
      </el-col>
      <div class="smallbtn" @click="showmenu()">|||</div>
      <el-col :span="16" class="hidden-xs-only">
        <div class="title_right">
          <div class="box afLine">
            <div class="icon"><img src="../assets/images/location.svg" /></div>
            <div class="textDiv">
              <span>广东省</span>
              <span>深圳市罗湖区</span>
            </div>
          </div>
          <div class="box afLine">
            <div class="icon"><img src="../assets/images/mobile.svg" /></div>
            <div class="textDiv">
              <span>0755-8885 9986</span>
              <span>sales@szjhr.cn</span>
            </div>
          </div>
          <div class="box afLine">
            <div class="iconwechat">
              <img src="../assets/images/wechat.png" />
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 大页面样式 -->
    <el-row type="flex" justify="end" class="hidden-xs-only menuBg">
      <el-col :span="23" class="longMenu">
        <div v-for="(val, key) in menuDatas" :key="key" class="menuList">
          <a v-if="val.cnName === '查询系统'" target="_blank" href="https://www.cnhscode.com/createcustoms" class="el-link"
            :class="val.bkgState ? 'selected' : ''">{{
              val.cnName }}</a>
          <a v-else :href="'/' + key" class="el-link" :class="val.bkgState ? 'selected' : ''">{{ val.cnName }}</a>
          <div class="subMenu" v-if="val.subMenu">
            <a class="sub-link" :href="'/' + key + '/' + subKey" v-for="(subVal, subKey) in val.subMenu"
              :key="subKey">{{ subVal.cnName }}</a>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 小页面样式 -->

    <el-row v-show="menuState" type="flex" justify="end" class="hidden-sm-and-up menuBg">
      <el-col :span="24" class="sortMenu">
        <div v-for="(val, key) in menuDatas" :key="key" class="menuList">
          <div class="el-link" :class="val.bkgState ? 'selected' : ''">
            <!-- 子菜单 -->
            <div v-if="val.subMenu" style="width: 100%">
              <div class="el-link" :class="val.bkgState ? 'selected' : ''" @click="ellinkClk(key)">
                {{ val.cnName }}
              </div>
              <div :class="val.sortMenuList ? 'subMenu showSubMenu' : 'subMenu'">
                <a class="sub-link" v-for="(subVal, subKey) in val.subMenu" :key="subKey"
                  :href="'/' + key + '/' + subKey">
                  {{ subVal.cnName }}
                </a>
              </div>
            </div>
            <!-- 无子菜单 -->
            <div v-else class="el-link">
              <a class="el-link" :class="val.bkgState ? 'selected' : ''" :href="'/' + key">{{ val.cnName }}</a>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-row>
</template>


<script>
export default {
  data() {
    return {
      menuState: false, //小菜单状态
      menuDatas: {
        index: {
          bkgState: true, //背景颜色
          sortMenuList: false, //小菜单中的子菜单显示与否
          cnName: "主页",
          subMenu: false,
        },
        agent: {
          bkgState: false,
          sortMenuList: false,
          subMenu: {
            lcl: { bkgState: false, cnName: "散货仓单证" },
            import: { bkgState: false, cnName: "进口代理" },
            export: { bkgState: false, cnName: "出口代理" },
            woodimport: { bkgState: false, cnName: "木材进口" },
            expressdoc: { bkgState: false, cnName: "国际快递单证服务" },
          },
          cnName: "进出口代理",
        },
        supply: {
          bkgState: false,
          sortMenuList: false,
          cnName: "供应链服务",
          subMenu: false,
        },
        logistics: {
          bkgState: false,
          sortMenuList: false,
          cnName: "物流",
          subMenu: false,
        },
        retailers: {
          bkgState: false,
          sortMenuList: false,
          cnName: "跨境电商解决方案",
          subMenu: false,
        },
        systems: {
          bkgState: false,
          sortMenuList: false,
          cnName: "查询系统",
          subMenu: false,
        },
        aboutus: {
          bkgState: false,
          sortMenuList: false,
          cnName: "关于我们",
          subMenu: false,
        },
      },
    };
  },
  methods: {
    //小菜单点击按钮
    showmenu() {
      this.menuState = !this.menuState;
    },
    //小菜单点击
    /**
     * @小菜单点击事件
     * menuDatas下有子菜单则显示，设置sortMenuList取反
     * 同时隐藏其他有子菜单的sortMenuList
     */
    ellinkClk(key) {
      //当前点击按钮有子菜单
      // console.log(this.menuDatas[key]);
      if (this.menuDatas[key].subMenu) {
        for (let i in this.menuDatas) {
          if (i === key) {
            this.menuDatas[key].sortMenuList = !this.menuDatas[key]
              .sortMenuList;
          }
          else {
            this.menuDatas[i].sortMenuList = false;
          }
        }
      }
    },
    /**
     * @监听路由更改
     * 从watch勾子触发watchURL函数
     * 从this.$route中获取路由的name属性，从而调取data中对应的数据进行设置
     * 路由改变先将所有背景色、下拉子菜单设置为初始状态false
     * 再将当前路由下的菜单背景色高亮
     */
    watchURL() {
      let pageName = this.$route.matched[0].name;
      //判断是在对象内的数据
      // if(Object.keys(this.menuDatas).indexOf(pageName)!=-1) console.log(pageName);
      for (let i in this.menuDatas) {
        this.menuDatas[i].bkgState = false;
        this.menuDatas[i].sortMenuList = false;
      }
      //设置样式
      this.menuDatas[pageName].bkgState = true;
    },
  },
  watch: {
    //监听路由改变
    $route() {
      this.watchURL();
    },
  },
};
</script>
<style lang="less" scoped>
@menuHeight: 50px;

.el-row {
  display: flex;
  flex-direction: column;

  .el-row:first-child {
    height: 108px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .smallMenuBtn,
    .smallbtn {
      text-align: center;
      align-items: center;
      font-size: 25px;
      color: #666;
      display: flex;
      justify-content: center;
      flex-direction: row;
      transform: rotate(-90deg) scaleX(1.5);
      cursor: pointer;
      margin-right: 30px;
    }

    @media screen and(min-width:768px) {
      .smallbtn {
        display: none;
      }
    }

    .el-col {
      display: flex;
      align-items: center;
      min-width: 120px;

      a {
        margin: 0 25%;
      }

      .title_right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-right: 60px;

        .box {
          display: flex;
          flex-direction: row;
          margin-left: 18px;
          position: relative;

          .iconwechat {
            padding-left: 20px;

            img {
              width: 60px;
              height: 60px;
            }
          }

          .icon {
            display: flex;
            align-items: center;
            height: 100%;
            padding-right: 10px;

            img {
              height: 100%;
              padding-left: 10px;
              width: 20px;
              display: flex;
              align-items: center;
            }
          }

          .textDiv {
            display: flex;
            flex-direction: column;
            padding-top: 12px;

            span {
              text-align: left;
              font-size: 15px;
              min-width: 120px;
              color: #2d444f;
            }
          }
        }

        .box::before {
          content: "|";
          position: absolute;
          top: 22%;
          left: -10%;
          font-size: 25px;
          transform: scaleX(0.3);
        }

        .box:first-child::before {
          content: "";
        }
      }
    }
  }

  .menuBg {
    background: #2d444f;
    box-shadow: 1px 0px 2px #111;
  }

  .longMenu {
    height: @menuHeight;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    z-index: 1;

    .el-link {
      color: #fff;
      line-height: 50px;
      padding: 0 10px;
      font-size: 15px;
      text-shadow: 0px 0px 1px #333;
    }

    .subMenu {
      width: 100vw;
      position: absolute;
      color: white;
      height: @menuHeight;
      background: #57869c;
      left: 0;
      visibility: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .sub-link {
        color: #fff;
        padding: 0 30px;
        text-decoration: none;
        line-height: @menuHeight;
        text-align: center;
        box-sizing: border-box;
        position: relative;
        font-weight: 500;
        font-size: 14px;
        text-shadow: 1px 1px 2px #111;
      }

      .sub-link::after {
        content: "";
        width: 0;
        position: absolute;
        border: 0px solid white;
        left: 0;
        bottom: 0;
        transition: all 0.5s ease-in-out;
      }

      .sub-link:hover::after {
        width: 100%;
        border-bottom: 2px solid #fff;
      }
    }
  }

  .menuList {
    transition: all 0.3s ease-in-out;
  }

  .menuList:hover {
    background-color: #57869c;
  }

  .menuList:hover .subMenu {
    visibility: visible;
  }

  .sortMenu {
    display: block;
    background-color: #2d444f;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    animation: fade-in 0.5s;

    .el-link {
      color: #fff;
      width: 100%;
      line-height: 30px;
      padding: 5px 0;
      font-size: 15px;
      width: 100%;
      transition: all 0.3s ease-in-out;
      text-shadow: 0px 0px 1px #333;
    }

    .showSubMenu {
      display: flex !important;
      opacity: 1 !important;
      transition: all 1 ease-in;
    }

    .subMenu {
      width: 100vw;
      color: white;
      background: #345766;
      display: none;
      flex-direction: column;
      opacity: 0;
      transition: all 0.3 ease-in-out;

      .sub-link {
        color: #ddd;
        text-decoration: none;
        line-height: @menuHeight;
        box-sizing: border-box;
        position: relative;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        text-shadow: 0px 0px 2px #000;
      }

      .sub-link::after {
        content: "";
        width: 0;
        position: absolute;
        border: 0px solid white;
        left: 0;
        bottom: 0;
        transition: all 0.5s ease-in-out;
      }

      .sub-link:hover::after {
        width: 100%;
        border-bottom: 2px solid #fff;
      }
    }
  }

  .selected {
    background-color: #57869c;
    text-shadow: 1px 1px 2px #111 !important;
  }

  .logo {
    display: flex;
    width: 150px;
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }

  // 菜单动画
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 100%;
    }
  }
}
</style>