<template>
  <div class="footer">
    <div class="box">
      <div class="outcontainer">
        <div class="listbox">
          <h3>关于我们</h3>
          <ul>
            <li><a href="/aboutus">联系我们</a></li>
            <li><a href="/aboutus">商务合作</a></li>
          </ul>
        </div>
        <div class="listbox">
          <h3>优势领域 </h3>
          <ul>
            <li><a href="/agent/import">代理进口</a></li>
            <li><a href="/agent/export">出口报关</a></li>
            <li><a href="/supply">供应链服务</a></li>
            <li><a href="/logistics">物流运输</a></li>
          </ul>
        </div>
        <div class="listbox">
          <h3>查询系统</h3>
          <ul>
            <li><a target="_blank" href="https://cnhscode.com/createcustoms">海关编码查询</a></li>
            <li><a target="_blank" href="http://www.szjhr.cn/citycode.php">城市三字代码</a></li>
            <li><a target="_blank" href="http://www.szjhr.cn/oceanport.php">海运港口中英文</a></li>
            <li><a target="_blank" href="http://www.szjhr.cn/airline.php">航空代码</a></li>
          </ul>
        </div>
        <div class="listbox">
          <h3>在线联系</h3>
          <div class="wechat">
            <img src="../assets/images/contentImgs/wechart_black.png" />
            <img src="../assets/images/contentImgs/wechart_black.png" />
          </div>
        </div>
      </div>
      <div class="ipc">
        <div>
          网站备案 | 许可证号：<a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备13057959号</a>
        </div>
        <div class="companyInfo">
          CopyRight © 2013 SHENZHEN JIFFY COMPANY ALL RIGHTS RESERVED.
          深圳市捷汇锐进出口有限公司 | 版权所有 | 电话：+86 755 88859986
        </div>
        <div class="companyInfo">
          地址：深圳市罗湖区金稻田路紫荆花园A栋30D 邮箱：sales@szjhr.cn
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.footer {
  background-color: #2e3033;
  color: white;
  font-family: "微软雅黑", sans-serif;
  width: 100%;
  //   display: inline-flex;

  display: flex;
  justify-content: center;
  position: relative;
}

.footer::before {
  content: "";
  position: absolute;
  border-bottom: 0px solid #fff;
  width: 100%;
  height: 50px;
  top: -50px;
  left: 0;
  box-shadow: 0px 50px 40px #1a1b1d;
}

.box {
  width: 80%;
}

.outcontainer {
  padding: 70px 0 50px;
  display: flex;
  justify-content: space-between;
  position: relative;

  a {
    color: #848589;
    text-decoration: none;
  }

  h3 {
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      font-size: 14px;
      line-height: 30px;
      color: #848589;
    }
  }

  .wechat {
    img {
      width: 118px;
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .outcontainer {
    flex-direction: column;
    padding-bottom: 80px;
  }

  .companyInfo {
    display: none;
  }
}

.outcontainer::after {
  content: " ";
  width: 100%;
  border-bottom: 1px solid #848589;
  position: absolute;
  bottom: 0;
  transform: scaleY(0.5);
}

.ipc {
  font-size: 13px;
  padding: 30px 0 40px 0;
  color: #848589;
  line-height: 25px;

  a {
    text-decoration: none;
    color: #848589;
  }
}
</style>