import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () =>
    import('views/Home.vue')
const Agent = () =>
    import('views/Agent.vue')
const Lcl = () =>
    import('components/agentsubpage/lcl.vue')
const Import = () =>
    import('components/agentsubpage/import.vue')
const Export = () =>
    import('components/agentsubpage/export.vue')
const Expressdoc = () =>
    import('components/agentsubpage/expressdoc.vue')
const Woodimport = () =>
    import('components/agentsubpage/woodimport.vue')
const Supply = () =>
    import('views/Supply.vue')
const Logistics = () =>
    import('views/Logistics.vue')
const Retailers = () =>
    import('views/Retailers.vue')
const Aboutus = () =>
    import('views/Aboutus.vue')
Vue.use(VueRouter)

/**
 * @routes中需以下字段属性
 * path 路径
 * name 别名 用于后面$route调用
 * meta 设置title、<meta>标签中的keywords,及描述
 */

const routes = [{
    path: '',
    name: 'index',
    meta: {
        title: "深圳市捷汇锐进出口有限公司   -  深圳报关|澳酸红木优品|让进出口报关不再神秘",
        content: {
            keywords: '深圳进出口公司|深圳一手报关行|皇岗报关|盐田报关行|盐田包柜|买单报关|深圳报关|盐田进口',
            description: '深圳市捷汇锐进出口有限公司是一手深圳报关行企业，提供一手报关业务:盐田报关、蛇口报关、文锦渡出口报关、皇岗报关、深圳机场报关等。主要产品的报关服务:服装包柜、鞋子出口、家私包柜、灯具包柜、玩具出口包柜综合物流供应链服务为一体服务企业。'
        }
    },
    component: Home,
},
{
    path: '/index',
    name: 'index',
    meta: {
        title: "深圳市捷汇锐进出口有限公司   -  深圳报关|澳酸红木优品|让进出口报关不再神秘",
        content: {
            keywords: '深圳进出口公司|深圳一手报关行|皇岗报关|盐田报关行|盐田包柜|买单报关|深圳报关|盐田进口',
            description: '深圳市捷汇锐进出口有限公司是一手深圳报关行企业，提供一手报关业务:盐田报关、蛇口报关、文锦渡出口报关、皇岗报关、深圳机场报关等。主要产品的报关服务:服装包柜、鞋子出口、家私包柜、灯具包柜、玩具出口包柜综合物流供应链服务为一体服务企业。'
        }
    },
    component: Home,
},
{
    path: '/agent',
    name: "agent",
    component: Agent,
    children: [{
        path: '',
        meta: {
            title: "进出口代理",
            content: {
                keywords: '进出口代理|深圳进出口报关',
                description: '专业报关，进出口货物代理，提供专业的进出口解决方案'
            }
        },
        component: Lcl,
    },
    {
        path: 'lcl',
        meta: {
            title: "散货仓单证",
            content: {
                keywords: '散货入仓报关|散货仓报关资料|海运散货报关',
                description: '企业出货货物以金运达货仓、外运仓、梅园仓、嘉里仓,文辉仓,马士基仓,力又仓,裕泰仓,勤辉仓等海运散货入仓所需向海关提交的报关资料文件，我司可代理提供一交点式服务。企业只需提供货物的品名装箱信息、产品价值等相关资料，即可快捷办理好出口相关文件。'
            }
        },
        component: Lcl,
    },
    {
        path: 'import',
        meta: {
            title: "进口代理",
            content: {
                keywords: '进口代理|深圳进口报关|宝安机场进口报关|皇岗进口报关',
                description: '为企业代理货物的进口申报、税费缴纳代办服务，解决企业进口难等相关问题，依托保税物流、仓储等多元化的基础服务，为企业提供优质的进口解决方案'
            }
        },
        component: Import,
    },
    {
        path: 'export',
        meta: {
            title: "出口代理",
            content: {
                keywords: '出口代理|出口报关|深圳包柜|盐田出口包柜',
                description: '拥有多年专业的进出口报关经验，为企业提供深圳一手的进出口代理服务。捷汇锐有专业报关团队，代理深圳盐田、蛇口、皇岗、机场UPS、FEDEX、TNT、DHL进出口报关报检服务，深圳一手报关值得信赖。'
            }
        },
        component: Export,
    },
    {
        path: 'expressdoc',
        meta: {
            title: "国际快递单证服务",
            content: {
                keywords: '国际快递报关|机场快递报关服务',
                description: '国际快递物流经UPS，FEDEX，DHL ，TNT承运的物品，我司提供向出口申报单证，再经国快递公司向海关申报，降低企业的出口成本，有效提升企业的出口时效'
            }
        },
        component: Expressdoc,
    },
    {
        path: 'woodimport',
        meta: {
            title: "木材进口",
            content: {
                keywords: '红木进口|濒危木材清关|原木清关',
                description: '多年代理红木、原材进口，为客户提供红木濒危证国内换证、进口清关、商检、税费缴纳及到港后的货物派送等，为客户提供省心安全的优质服务。'
            }
        },
        component: Woodimport,
    },
    ]
},
{
    path: "/supply",
    name: "supply",
    meta: {
        title: "供应链服务",
        content: {
            keywords: '供应链服务|供应链金融|信用证|外汇|出口退税|红木融资',
            description: '为企业提供专属供应链金融服务方案，为企业提升行业竞争力。'
        }
    },
    component: Supply,
},
{
    path: "/logistics",
    name: "logistics",
    meta: {
        title: "物流",
        content: {
            keywords: '国际快递|航空运输|海运|非洲进口订舱',
            description: 'UPS，DHL等国际快递、各国航空运输，非洲进口舱位预订等服务,我们以同行业最具有价格优势的费用承运，安全有保障。'
        }
    },
    component: Logistics,
},
{
    path: "/retailers",
    name: "retailers",
    meta: {
        title: "跨境电商解决方案",
        content: {
            keywords: '跨境电商|电商物流|跨境快递|国外仓储|跨境小包裹',
            description: '去繁化简将跨境电商融合物流、海关申报、仓储、派送等综合物流方案解决跨境电商企业国外货物管理难，仓储物流成本高的问题。'
        }
    },
    component: Retailers,
},
{
    path: "/aboutus",
    name: "aboutus",
    meta: {
        title: "捷汇锐",
        content: {
            keywords: '捷汇锐|捷汇锐进出口公司|关于我们|进出口公司|报关公司',
            description: '关于捷汇锐，我们以客户为依托，服务好每一位客户，提供我们真诚优质的服务。'
        }
    },
    component: Aboutus,
},
{
    path: '*',
    redirect: '/',
    meta: {
        title: "深圳市捷汇锐进出口有限公司   -  深圳报关|让进出口报关不再神秘",
        content: {
            keywords: '深圳进出口公司|深圳一手报关行|皇岗报关|盐田报关行|盐田包柜|买单报关|深圳报关|盐田进口',
            description: '深圳市捷汇锐进出口有限公司是一手深圳报关行企业，提供一手报关业务:盐田报关、蛇口报关、文锦渡出口报关、皇岗报关、深圳机场报关等。主要产品的报关服务:服装包柜、鞋子出口、家私包柜、灯具包柜、玩具出口包柜综合物流供应链服务为一体服务企业。'
        }
    },
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    //设置meta的keywords和description
    document.querySelector('meta[name="keywords"]').content = to.meta.content.keywords
    document.querySelector('meta[name="description"]').content = to.meta.content.description
    if (to.meta.title) window.document.title = to.meta.title
    next()
})
export default router