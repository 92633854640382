import Vue from 'vue'
import { Button ,Row,Col,Image,Link} from 'element-ui'
// import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'


Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Link)
// Vue.use(CollapseTransition)